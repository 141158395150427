import React from 'react';
import Helmet from 'react-helmet';
import 'semantic-ui-less/semantic.less';

import '../style/projects.css';
import Layout from '../components/Layout';
import Projects from '../components/Projects';

export default class extends React.Component {
  render() {
    const { edges } = this.props.data.allAirtable;
    const projects = edges.map((edge) => edge.node.data);

    return (
      <Layout>
        <Helmet
          title={`Full Scope Web`}
          meta={[
            { name: 'description', content: 'Full Scope Web featured portfolio projects includes startup websites, small business websites, and artist websites.' },
          ]}
        />
        <Projects projects={projects.slice(0, 9)} />
      </Layout>
    )
  }
};

export const query = graphql`
  query {
    allAirtable(filter: {table: {eq: "Projects"}}) {
      edges {
        node {
          data {
            title
            description
            link
            thumbnail {
              url
            }
          }
        }
      }
    }
  }
`;