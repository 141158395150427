import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Link } from 'gatsby';

import { toHyphenCase } from '../utils/comm';
import Project from './ProjectCard';

export default ({ projects }) => (
  <Grid columns={3} className="projects" stackable>
    {projects.map(project => (
      <Grid.Column>
        <Link to={`projects/${toHyphenCase(project.title)}`}>
          <Project {...project} />
        </Link>
      </Grid.Column>
    ))}
  </Grid>
);